<template>
  <BaseElSelect
    v-model="syncModel"
    v-bind="$attrs"
    :multiple="multiple"
    filterable
    remote
    clearable
    reserve-keyword
    placeholder="搜尋服務設備"
    no-match-text="無匹配項目"
    no-data-text="暫無數據"
    :remote-method="remoteSearch"
    :loading="loading"
    value-key="id"
    @change="$emit('change')"
    @clear="getResourceItem"
  >
    <i slot="prefix" class="el-input__icon el-icon-search" />
    <BaseElSelectOption
      v-for="item in resourceItemList"
      :key="item.id"
      :label="item.name"
      :value="item"
    />
  </BaseElSelect>
</template>

<script>
import { GetResourceItem } from '@/api/resource'
import { mapGetters } from 'vuex'
export default {
  name: 'ResourceSearch',
  props: ['model', 'type', 'multiple'],

  data: () => ({
    loading: false,
    resourceItemList: [],
  }),

  computed: {
    ...mapGetters([
      'shop',
    ]),
    syncModel: {
      get () {
        return this.model
      },

      set (data) {
        this.$emit('update:model', data)
      },
    },
  },

  async mounted () {
    await this.getResourceItem()
  },

  methods: {

    async remoteSearch (query) {
      query = query.trim()
      if (query === '') this.resourceItemList = []

      try {
        this.loading = true
        setTimeout(async () => {
          await this.getResourceItem(query)
          this.loading = false
        }, 200)
      } catch (error) {
        console.log(error)
      }
    },

    async getResourceItem (query = null) {
      const [res, err] = await GetResourceItem({
        shopId: this.shop,
        name: (query) || undefined,
        start: 0,
        limit: 50,
      })
      if (err) window.$message.error(err)
      this.resourceItemList = res
    },
  },

}
</script>

<style scoped lang="scss"></style>
